// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { subNavRow, subNavRowSticky } from './index.module.scss';

type Props = {
  children: Node,
  className?: string | null,
  height?: string,
  top?: string,
};
const SubNav = ({
  children, className = null, height = '50px', top = '61px',
}: Props): Element<typeof Row> => (
  <Row className={subNavRow} style={{ height, top }}>
    <Col className={classNames(subNavRowSticky, className)} style={{ height }}>
      {children}
    </Col>
  </Row>
);

export default SubNav;
