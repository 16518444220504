import { createSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import {
  IS_CUSTOM,
  MODEL_COMPONENT_POPULATION_ITEMS, RUN_COMPONENT_POPULATION_ITEMS, UPDATED_AT,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { selectInvisibleFields, selectTouchedFields } from '#selectors/ui/form/component.js';
import { selectComponentByUuid, selectModelsFromComponentByUuid } from '#selectors/entities/components.js';
import makeCalculateErrorCountBySection from '#selectors/ui/support/makeCalculateErrorCountBySection.js';
import getComponentDetailSectionNameFromUnqualifiedFieldName
  from '#support/components/componentDetailView/sectionNameFromUnqualifiedFieldName.js';

const calculateErrorCountBySection = makeCalculateErrorCountBySection(
  getComponentDetailSectionNameFromUnqualifiedFieldName
);

export const selectErrorCountBySection = createSelector(
  getFormSyncErrors(COMPONENT_FORM),
  selectTouchedFields,
  selectInvisibleFields,
  (errors, touched, invisible) => calculateErrorCountBySection(
    errors,
    touched,
    fieldsByName,
    invisible
  )
);

export const selectComponentModelUsageWarningsByModelId = createSelector(
  selectComponentByUuid,
  selectModelsFromComponentByUuid,
  (component, models) => {
    if (!component || !component[IS_CUSTOM] || !models) {
      return {};
    }
    return models.reduce((acc, model) => {
      const { uuid: modelId, currentRunId } = model;
      const modelPopulationItem = component[MODEL_COMPONENT_POPULATION_ITEMS]
        .find((item) => (modelId === item.modelId));

      const runPopulationItem = component[RUN_COMPONENT_POPULATION_ITEMS]
        .find((item) => (currentRunId === item.runId));

      return (
        modelPopulationItem
        && runPopulationItem
        && new Date(runPopulationItem.updatedAt) < new Date(component[UPDATED_AT])
      )
        ? {
          ...acc,
          [modelId]: "Component edited since model's most recent run",
        } : acc;
    }, {});
  },
);

export const selectComponentModelsWithUsageWarnings = createSelector(
  selectModelsFromComponentByUuid,
  selectComponentModelUsageWarningsByModelId,
  (models, warnings) => {
    if (!Array.isArray(models)) {
      return [];
    }
    if (!warnings) {
      return models;
    }
    return models.map((model) => (
      {
        ...model,
        warning: warnings[model.uuid] || null,
      }
    ));
  },
);

export const selectWarningCountBySection = createSelector(
  selectComponentModelUsageWarningsByModelId,
  (warnings) => ({
    modelUsage: Object.keys(warnings).length,
  }),
);
