// @flow
import classNames from 'classnames';
import type { Element } from 'react-table';
import React from 'react';
import ReactTable from 'react-table';
import { columnsWithDefaults } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.jsx';
import { modelsTable, fixedHeader } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';
import modelColumn from '#components/pages/Components/Component/sections/ModelUsage/ModelUsageTable/columns/modelColumn.jsx';
import projectColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/projectColumn.jsx';
import authorColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/authorColumn.jsx';
import createdDateColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/createdDateColumn.jsx';
import updatedDateColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/updatedDateColumn.jsx';
import warningsColumn
  from '#components/pages/Components/Component/sections/ModelUsage/ModelUsageTable/columns/warningsColumn.jsx';

const columns = columnsWithDefaults([
  modelColumn,
  projectColumn,
  authorColumn,
  createdDateColumn,
  updatedDateColumn,
  warningsColumn,
]);

type Props = {
  models: Object,
};

const ModelUsageTable = ({ models }: Props): Element<typeof ReactTable> => (
  <ReactTable
    className={classNames(modelsTable, '-striped', 'rounded')}
    columns={columns}
    data={models}
    minRows={20}
    showPagination={false}
    resizable={false}
    style={{ height: '500px' }}
    getTheadProps={() => ({ className: fixedHeader })}
  />
);

export default ModelUsageTable;
