// @flow
import type { Element } from 'react';
import type { Fields } from 'redux-form/lib/FieldArrayProps.types';
import React from 'react';
import {
  DAMAGE_STATE_CONSEQUENCES,
  DAMAGE_STATES,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';
import type { Field } from '#components/pages/Components/support/tables/support/types/field';

import {
  TableField,
  Label,
} from '#src/js/components/pages/Components/support/form/index.js';
import EntityModeTableCellContent
  from '#src/js/components/pages/Components/support/tables/support/EntityModeTableCellContent/index.js';

const getConsequenceNames = (damageStates) => damageStates.reduce((acc, damageState, i) => {
  const { consequences } = damageState;
  const names = consequences.map((consequence, j) => `${DAMAGE_STATES}[${i}].${DAMAGE_STATE_CONSEQUENCES}[${j}]`);
  return [
    ...acc,
    ...names,
  ];
}, []);

type EntityModeRowProps = {|
  entityMode: true,
  component: Component,
  fieldSchema: Field,
  visible: boolean,
|};

type FormModeRowProps = {|
  entityMode: false,
  fields: Fields,
  fieldSchema: Field,
  visible: boolean,
  readOnly?: false,
|};

type RowProps = EntityModeRowProps | FormModeRowProps;

/* eslint-disable react/destructuring-assignment, react/require-default-props */

// In this component we purposefully avoid destructuring props so that flow can properly
// handle TableProps as union type
const Row = (props: RowProps): Element<'tr'> | null => {
  if (!props.visible) {
    return null;
  }
  const damgaeState = props.entityMode
    ? props.component.formInputs.values.damageStates
    : props.fields.getAll();

  const consequences = getConsequenceNames(damgaeState);
  return (
    <tr>
      <td>
        <Label field={props.fieldSchema} component="span" />
      </td>
      {
        consequences.map((consequence) => (
          <td key={consequence}>
            {
              props.entityMode
                ? (
                  <EntityModeTableCellContent
                    component={props.component}
                    item={consequence}
                    field={props.fieldSchema}
                  />
                ) : (
                  <TableField
                    item={consequence}
                    field={props.fieldSchema}
                    readOnly={props.readOnly}
                  />
                )
            }
          </td>
        ))
      }
    </tr>
  );
};

export default Row;
