// @flow
import type { Element } from 'react';

import React, { useState } from 'react';
// eslint-disable-next-line import/extensions
import { GoCheck } from 'react-icons/go';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import { button, buttonContainer, messageContainer } from '#components/pages/Components/Component/Sidebar/ValidateButton/ValidateButton.module.scss';

type Props = {
  handleSubmit: Function,
  isValid: boolean,
  show: boolean,
};

const ValidateButton = ({ handleSubmit, isValid, show }: Props): Element<'div'> | null => {
  const [validating, setValidating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleClick = (e) => {
    setValidating(true);
    handleSubmit(e);
    setTimeout(() => {
      setValidating(false);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2500);
    }, 500);
  };

  return show ? (
    <div className={buttonContainer}>
      <SubmitButton
        submitting={validating}
        submittingText="Validating"
        onClick={handleClick}
        color="primary"
        className={button}
      >
        Validate
      </SubmitButton>
      {
        showMessage && isValid
        && (
          <div className={messageContainer}>
            <span>
              Valid
              {' '}
              <GoCheck className="text-success" />
            </span>
          </div>
        )
      }
    </div>
  ) : null;
};

export default ValidateButton;
